<template>
  <div
    id="app"
    class="flex flex-col justify-start font-light text-sm lg:text-base"
    :style="{
      overflow: disableScroll ? 'hidden' : 'auto',
    }"
  >
    <AppHeader />
    <HorizontalMenu v-if="!loading" />
    <div class="flex flex-col lg:flex-row">
      <div
        v-if="$route.name === 'Home'"
        class="mx-5 lg:mr-0 mb-5 lg:ml-5 p-3 lg:p-5 bg-slate-200 dark:bg-slate-700 rounded-md shadow-md"
      >
        <AppSearch />
        <AppFilters @reset-filters="() => traitsComponentRef.clearActiveTraits()" />
        <AppTraits v-if="!loading" ref="traitsComponentRef" />
      </div>
      <div class="grow mx-5 mb-5 bg-slate-200 dark:bg-slate-700 rounded-md shadow-md overflow-hidden">
        <router-view />
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import useApp from '@/composables/useApp'
import useAssetList from '@/composables/useAssetList'
import useNamecoin from '@/composables/useNamecoin'
import useTraits from '@/composables/useTraits'

import AppFilters from '@/components/AppFilters.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSearch from '@/components/AppSearch.vue'
import AppTraits from '@/components/AppTraits.vue'
import HorizontalMenu from '@/components/HorizontalMenu.vue'

const {
  state: { disableScroll },
  enableScroll,
} = useApp()

const route = useRoute()
const { loadAssets } = useAssetList(route)
const { setChainData } = useNamecoin()

const { traits, loadingTraits } = useTraits()

const traitsComponentRef = ref()

const loading = computed(() => loadingTraits.value || !traits.value.length)

enableScroll()

onMounted(async () => {
  window.addEventListener('popstate', () => loadAssets())
  await setChainData()
})
</script>

<style>
.dotnav {
  place-content: center;
  inline-size: -webkit-fill-available;
  overflow-x: auto;
}

.dotnav div {
  min-width: 15px;
  max-width: 15px;
}
.spinner {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid rgb(15, 23, 42);
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
