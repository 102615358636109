<template>
  <div class="footer flex flex-row p-8 md:p-10 mt-auto bg-slate-200 dark:bg-slate-700">
    <div>
      © {{ year }} nmc.vision - All Rights Reserved. Created with ❤️ by
      <a target="_blank" href="https://www.nft1.com/">NFT1 Team</a> &
      <a target="_blank" href="https://twitter.com/punk3606">archivist.eth</a>
    </div>
    <div class="icons">
      <a href="https://twitter.com/nmc_vision" target="_blank">
        <TwitterIcon class="mr-2 w-6 h-6 text-slate-600 dark:text-slate-100" fill="currentColor" />
      </a>
    </div>
  </div>
</template>

<script setup>
import TwitterIcon from '@/components/TwitterIcon.vue'
const year = new Date().getFullYear()
</script>

<style scoped>
.icons {
  margin-left: auto;
}
@media screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
  }
}
</style>
