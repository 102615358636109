import { useLocalStorage } from '@vueuse/core'
import { watch } from 'vue'

const darkMode = useLocalStorage('darkMode', true)

export default function useDarkMode() {
  const onDarkClicked = () => (darkMode.value = true)

  const onLightClicked = () => (darkMode.value = false)

  const initializeDarkMode = () => {
    if (darkMode.value === true) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }

  initializeDarkMode()

  watch(
    () => darkMode.value,
    () => {
      if (darkMode.value === true) {
        document.documentElement.classList.add('dark')
      } else {
        document.documentElement.classList.remove('dark')
      }
    },
  )

  return {
    onDarkClicked,
    onLightClicked,
    darkMode,
  }
}
