<template>
  <a :href="`/?trait=${trait.slug}`" :class="mainClass">
    {{ trait.name }}
  </a>
</template>

<script>
export default {
  props: ['trait', 'activeTrait'],

  computed: {
    isActive() {
      return this.trait.slug === this.activeTrait
    },

    mainClass() {
      const defaultClass =
        'text-bold mr-2 last:mr-0 bg-slate-700 dark:bg-slate-800 hover:bg-blue-500 dark:hover:bg-blue-500 px-3 py-1 text-slate-100 rounded-md shadow-md cursor-pointer border border-slate-600 hover:border-blue-500'
      const activeClass =
        'text-bold mr-2 last:mr-0 bg-blue-600 hover:bg-blue-700 px-3 py-1 text-slate-100 rounded-md shadow-md cursor-pointer border border-blue-600 hover:border-blue-700 font-bold'

      return this.isActive ? activeClass : defaultClass
    },
  },
}
</script>
