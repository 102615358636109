import { ref } from 'vue'
import { WEBSOCKET_URL } from '@/constants/appConstants'
import useProgress from '@/composables/useProgress'

export let webSocket = new WebSocket(`${WEBSOCKET_URL}`)

export const clearAssetsOnNewEntries = ref(false)
export const assets = ref([])
export const loadingAssets = ref(false)
export const resultCount = ref(0)

export const connect = () => {
  webSocket = new WebSocket(`${WEBSOCKET_URL}`)

  webSocket.onmessage = ({ data }) => {
    try {
      if (data === 'ping') {
        webSocket.send('pong')
        return
      }

      const { assets: assetsToAdd, count, done } = JSON.parse(data)

      if (assetsToAdd) {
        if (clearAssetsOnNewEntries.value) assets.value.splice(0)
        assets.value.push(...assetsToAdd)
      }

      if (count !== undefined) resultCount.value = count

      if (done === true) {
        const { stopProgress } = useProgress()
        stopProgress()
        loadingAssets.value = false
        clearAssetsOnNewEntries.value = true
      }

      if (assetsToAdd?.length) clearAssetsOnNewEntries.value = false
    } catch (error) {
      console.error(error)
    }
  }

  webSocket.onclose = function (e) {
    setTimeout(() => connect(), 1000)
  }

  webSocket.onerror = function (err) {
    console.error('Socket encountered error: ', err.message, 'Closing socket')
    webSocket.close()
  }
}

connect()
