<template>
  <div
    class="overlay fixed z-50 backdrop-blur-sm w-full h-full top-0 left-0 p-5"
    style="background-color: rgba(51, 65, 85, 0.8)"
  >
    <div
      class="edit_asset relative mr-auto ml-auto w-full max-w-2xl border border-slate-600 rounded-md"
      style="margin: 0 auto"
    >
      <div class="flex items-center py-2 px-2 pl-4 bg-blue-500 font-bold text-slate-100 rounded-t-md">
        Share your thoughts about NMC.Vision
      </div>
      <div
        @click="$emit('close')"
        class="close absolute top-2 right-2 p-0.5 border border-slate-200 rounded-md cursor-pointer"
      >
        <CloseIcon />
      </div>
      <div v-if="submitted" class="inner p-3 bg-slate-200 dark:bg-slate-700 rounded-b-md overflow-scroll">
        Your message was submitted successfully, thank you! <br />NMC.vision team will review it shortly.
      </div>
      <div v-else class="inner p-3 bg-slate-200 dark:bg-slate-700 rounded-b-md overflow-scroll">
        <div>
          <label for="message" class="block pl-1 mb-1.5 mt-3 text-sm font-bold uppercase text-gray-900 dark:text-white"
            >Message</label
          >
          <textarea
            id="message"
            name="message"
            v-model="message"
            rows="6"
            class="w-full px-3 py-2 text-slate-900 border rounded-lg focus:outline-none"
            placeholder="Enter a message"
          />
          <div v-if="error" class="ml-1 nmc-v-red-text">{{ error }}</div>

          <div class="grid mt-1" style="place-content: center">
            <cfturnstile sitekey="0x4AAAAAAACJyD5TN6_HsXTU" @verify="verify" />
          </div>

          <button
            @click="onSubmitMessage"
            class="w-full text-bold hover:bg-blue-700 px-3 py-1 mt-3 text-slate-100 nmc-v-text-red-back dark:nmc-v-red-back rounded-md shadow-md cursor-pointer border border-red-600 hover:border-blue-700"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

import BackendApi from '@/apis/BackendApi'

const backendApi = BackendApi.create()

const error = ref('')
const message = ref('')
const reCaptchaToken = ref('')
const submitted = ref(false)

const onSubmitMessage = async () => {
  try {
    if (!message.value) {
      error.value = 'Message is required'
      return
    }

    if (!reCaptchaToken.value) {
      error.value = 'Please wait for the captcha to load, or try refreshing the page'
      return
    }

    await backendApi.postUserMessage({
      payload: {
        hex: null,
        trait_slug: null,
        message: message.value,
      },
      token: reCaptchaToken.value,
    })

    submitted.value = true
  } catch (error) {
    error.value = error
  }
}

const verify = (token) => (reCaptchaToken.value = token)
</script>

<script>
import Turnstile from 'cfturnstile-vue3'
import CloseIcon from '@/components/CloseIcon.vue'

export default {
  components: {
    cfturnstile: Turnstile,
    CloseIcon,
  },
}
</script>
