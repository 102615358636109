import { computed, ref, Ref } from 'vue'

import { allTrait } from '@/constants/traitsConstants'
import { getActiveParam, SearchParams } from '@/services/searchParams'
import BackendApi from '@/apis/BackendApi'

const backendApi = BackendApi.create()

const traits = ref([])
const loadingTraits = ref(false)

/** @type {Ref<string|null>} */
const activeTrait = ref(getActiveParam(SearchParams.trait))
/** @type {Ref<string[]>} */
const activeTraits = ref(getActiveParam(SearchParams.traits).split(',').filter(Boolean))

export default function useTraits(route) {
  const hydrateTraits = async (force = false) => {
    try {
      if (force) traits.value.splice(0)
      if (!force && (loadingTraits.value || traits.value.length)) return
      loadingTraits.value = true
      const traitsResponse = await backendApi.getTraits()
      traits.value.splice(0)
      traits.value.push(...[allTrait, ...traitsResponse])
    } catch (error) {
      console.error(error)
    } finally {
      loadingTraits.value = false
    }
  }

  hydrateTraits()

  const traitsWithoutAll = computed(() => {
    return traits.value.filter(({ slug }) => slug !== allTrait.slug)
  })

  const getTraitBySlug = (slug) => {
    if (!traits.value.length) return null
    return traits.value.find((trait) => trait.slug === slug)
  }

  return {
    activeTrait,
    activeTraits,
    getTraitBySlug, //
    hydrateTraits,
    loadingTraits,
    traits,
    traitsWithoutAll,
  }
}
