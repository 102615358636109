import { reactive, toRefs, watch } from 'vue'

import { disableScroll, enableScroll } from './useDisableScroll'

const stateName = 'nmc-vision-website'

const defaultState = {
  showFilters: 'false',
  disableScroll: false,
}

const getDefaultState = () => {
  if (localStorage.getItem(stateName) !== null) {
    return { ...defaultState, ...JSON.parse(localStorage.getItem(stateName)) }
  }
  return defaultState
}

const state = reactive(getDefaultState())

const actions = {
  disableScroll,
  enableScroll,
}

watch(
  () => state,
  () => {
    localStorage.setItem(stateName, JSON.stringify(state))
  },
  { deep: true },
)

// @TODO: Use vueuse useLocalStorage instead
export default () => {
  if (localStorage.getItem(stateName) === null) {
    localStorage.setItem(stateName, JSON.stringify(state))
  }
  return {
    state: toRefs(state),
    ...actions,
  }
}
