<template>
  <div
    id="traits"
    class="md:block pl-3 pr-3 pt-5 pb-3 md:pr-5 md:pl-5 md:pt-5 md:pb-3 mt-3 md:mt-5 lg:w-80 bg-slate-100 dark:bg-slate-800 rounded-md shadow-md"
    :style="`${windowWidth < 1024 && showFilters === 'false' && 'display: none'}`"
  >
    <div class="grid gap-2 md:gap-5 grid-cols-2">
      <label class="block mb-3 pl-1 font-bold text-slate-700 dark:text-slate-100">Traits</label>
      <label
        class="block mb-3 pl-1 text-sm font-normal text-slate-700 hover:text-slate-600 dark:text-slate-500 dark:hover:text-slate-400 text-right cursor-pointer"
        @click="onResetClicked"
      >
        Reset
      </label>
    </div>
    <div
      class="flex flex-col mb-2 border-slate-600"
      v-for="{ parent, children } in displayedRelations"
      :key="`main-trait-${parent.slug}`"
    >
      <div>
        <div
          class="flex bg-slate-700 dark:bg-slate-900 text-xs font-bold text-slate-100 rounded-md"
          @click="toggleChildVisibility(parent.slug)"
        >
          <span class="parent cursor-pointer py-2 px-3">
            {{ parent.name }}
          </span>
          <span class="dropdown cursor-pointer pb-2 pt-2.5 px-2 bg-slate-600 rounded-r-md">
            <UpIcon v-if="childrenVisibilityMap[parent.slug]" />
            <DownIcon v-else />
          </span>
        </div>
        <div class="child pl-3 pr-3 pb-3 pt-1" v-show="childrenVisibilityMap[parent.slug]">
          <div class="flex items-center mt-2" v-for="(child, i) in children" :key="`${child.slug}-${i}`">
            <input
              type="checkbox"
              :id="`${child.slug}-box`"
              :checked="isCheckboxChecked(child.slug)"
              @click="onCheckboxClicked(child.slug)"
              :disabled="isCheckboxDisabled(child.slug)"
              class="w-4 h-4 text-blue-600 bg-slate-100 rounded border-slate-300 ring-0 focus:ring-offset-0 dark:bg-slate-700 dark:border-slate-600"
              style="box-shadow: none"
            />
            <label class="ml-2 text-xs text-slate-900 dark:text-slate-300">
              <span class="font-bold">{{ child.name }}</span> <span>({{ child.supply.toLocaleString('en-US') }})</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { computed, ref, watch } from 'vue'

import { defaultTraitSlug } from '@/constants/traitsConstants'
import { SearchParams, updateSearchParams } from '@/services/searchParams'

import useApp from '@/composables/useApp'
import useAssetList from '@/composables/useAssetList'
import useBreakpoints from '@/composables/useBreakpoints'
import useRelations from '@/composables/useRelations'
import useTraits from '@/composables/useTraits'

import DownIcon from '@/components/DownIcon.vue'
import UpIcon from '@/components/UpIcon.vue'

const {
  state: { showFilters },
} = useApp()
const { activeTrait, activeTraits } = useTraits()

const route = useRoute()

const { loadAssets } = useAssetList(route)
const { relationsMap } = useRelations()
const { windowWidth } = useBreakpoints()

const childrenVisibilityMap = ref({})

const addAllToRelation = (relation) => {
  const allChild = { name: 'All', slug: `${relation.parent.slug}-all`, supply: relation.parent.supply }
  relation.children.unshift(allChild)
  return relation
}

const addAllToRelations = (relations) => relations.map(addAllToRelation)

const displayedRelations = computed(() => {
  if (activeTrait.value === defaultTraitSlug) {
    return addAllToRelations(
      Object.values(relationsMap.value).filter(
        ({ parent, children }) => (parent && children.length) || parent.is_main_trait,
      ),
    )
  }

  const activeTraitChildrenWithChildren = []

  const activeTraitChildren = relationsMap.value[activeTrait.value]?.children

  if (activeTraitChildren?.length) {
    const childrenSlugs = activeTraitChildren.map(({ slug }) => slug)
    const traitsWithChildren = childrenSlugs
      .map((slug) => relationsMap.value[slug])
      .filter(({ children }) => children.length)
    activeTraitChildrenWithChildren.push(...traitsWithChildren.map(({ parent: { slug } }) => slug))
  }

  const relations = addAllToRelations(
    [activeTrait.value, ...activeTraitChildrenWithChildren].map((slug) => relationsMap.value[slug]),
  )

  if (activeTraitChildrenWithChildren.length) {
    relations[0].children = relations[0].children.filter(({ slug }) => !activeTraitChildrenWithChildren.includes(slug))
  }

  if (activeTrait.value !== defaultTraitSlug) initializeChildrenVisibilityMap(relations)

  return relations
})

const initializeChildrenVisibilityMap = (relations) => {
  relations.forEach(({ parent }) => (childrenVisibilityMap.value[parent.slug] = true))
}

// For some reason the condition does not seem to work
// Consider checking it if there are bugs related to traits
const isSuperTrait = computed(() => displayedRelations.value.length > 1)

const doUpdateSearchParams = async () => {
  await updateSearchParams([
    { key: SearchParams.traits, value: activeTraits.value.join(',') },
    { key: SearchParams.page, value: '' },
  ])
  loadAssets()
}

const getChildrenSlugsFromAllChildSlug = (slug) => {
  const parentSlug = slug.substring(0, slug.indexOf('-all'))
  const mainTraitRelationsChildren = relationsMap.value[parentSlug].children
  return mainTraitRelationsChildren.map(({ slug }) => slug)
}

const isCheckboxChecked = (slug) => {
  if (slug.includes('-all') && isSuperTrait.value) {
    const parentSlug = slug.substring(0, slug.indexOf('-all'))
    if (parentSlug === activeTrait.value && !activeTraits?.value?.length) return true
    const childrenSlugs = getChildrenSlugsFromAllChildSlug(slug)
    return activeTraits.value.includes(parentSlug) && !activeTraits.value.some((slug) => childrenSlugs.includes(slug))
  }

  if (slug.includes('-all')) {
    const childrenSlugs = getChildrenSlugsFromAllChildSlug(slug)
    return !activeTraits.value.some((slug) => childrenSlugs.includes(slug))
  }

  return activeTraits.value.includes(slug)
}

const onCheckboxClicked = (slug) => {
  if (slug.includes('-all') && isSuperTrait.value) {
    const parentSlug = slug.substring(0, slug.indexOf('-all'))
    const parentAndChildrenSlugs = [parentSlug, ...relationsMap.value[parentSlug].children.map(({ slug }) => slug)]

    if (parentSlug === activeTrait.value) {
      activeTraits.value = []
      doUpdateSearchParams()
      return
    }

    if (activeTraits.value.includes(parentSlug)) {
      activeTraits.value = activeTraits.value.filter((slug) => !parentAndChildrenSlugs.includes(slug))
    } else {
      const traitsWithoutClickedTraitChildren = activeTraits.value.filter(
        (slug) => !parentAndChildrenSlugs.includes(slug),
      )
      activeTraits.value = [parentSlug, ...traitsWithoutClickedTraitChildren]
    }

    doUpdateSearchParams()
    return
  }

  if (slug.includes('-all')) {
    const childrenSlugs = getChildrenSlugsFromAllChildSlug(slug)

    if (!activeTraits.value.some((slug) => childrenSlugs.includes(slug))) {
      return
    } else {
      activeTraits.value = activeTraits.value.filter((slug) => !childrenSlugs.includes(slug))
      doUpdateSearchParams()
      return
    }
  }

  if (isSuperTrait.value) {
    if (activeTraits.value.includes(slug)) {
      activeTraits.value = activeTraits.value.filter((trait) => trait !== slug)
      doUpdateSearchParams()
      return
    }

    const parentSlug = displayedRelations.value.find(({ children }) =>
      children.some(({ slug: childSlug }) => childSlug === slug),
    ).parent.slug

    activeTraits.value = activeTraits.value.filter((trait) => trait !== parentSlug)
    activeTraits.value = [...activeTraits.value, slug]
    doUpdateSearchParams()
    return
  }

  activeTraits.value = activeTraits.value.includes(slug)
    ? activeTraits.value.filter((trait) => trait !== slug)
    : [...activeTraits.value, slug]

  doUpdateSearchParams()
}

const isCheckboxDisabled = (slug) => {
  if (!isSuperTrait.value || !slug.includes('-all')) return false
  const parentSlug = slug.substring(0, slug.indexOf('-all'))
  return parentSlug === activeTrait.value && !activeTraits?.value?.length
}

const onResetClicked = async () => {
  await updateSearchParams([
    { key: SearchParams.traits, value: '' },
    { key: SearchParams.page, value: '' },
  ])
  activeTraits.value = []
  loadAssets()
}

const toggleChildVisibility = (slug) => {
  childrenVisibilityMap.value[slug] = !childrenVisibilityMap.value[slug]
}

const clearActiveTraits = () => {
  activeTraits.value = []
}

defineExpose({
  clearActiveTraits,
})
</script>

<style scoped>
.parent {
  width: 100%;
}
</style>
