import { reactive } from 'vue'
import BackendApi from '@/apis/BackendApi'

const backendApi = BackendApi.create()

const chain = reactive({
  height: 0,
})

export default function useNamecoin() {
  const setChainData = async () => {
    const chainData = await backendApi.getChainData()
    chain.height = chainData.height
  }

  return {
    setChainData,
    chain,
  }
}
