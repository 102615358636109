import { computed, ref } from 'vue'

import { assets, loadingAssets, resultCount, webSocket, clearAssetsOnNewEntries } from '@/composables/webSocket'
import { getActiveParam, SearchParams } from '@/services/searchParams'
import useSorting from '@/composables/useSorting'
import useProgress from '@/composables/useProgress'

const searchMode = ref(getActiveParam(SearchParams.search_mode))

export default function useAssetList(route) {
  const getSearchOptions = (sortOption) => ({
    date_end: getActiveParam(SearchParams.date_end),
    date_start: getActiveParam(SearchParams.date_start),
    dir: getActiveParam(SearchParams.dir, { defaultOverride: sortOption.value.direction }),
    expired: getActiveParam(SearchParams.expired),
    limit: getActiveParam(SearchParams.limit),
    max_letters: getActiveParam(SearchParams.max_letters),
    min_letters: getActiveParam(SearchParams.min_letters),
    page: getActiveParam(SearchParams.page),
    search_mode: getActiveParam(SearchParams.search_mode),
    search: getActiveParam(SearchParams.search),
    sort_by: getActiveParam(SearchParams.sort_by, { defaultOverride: sortOption.value.field }),
    trait: getActiveParam(SearchParams.trait),
    traits: getActiveParam(SearchParams.traits),
    wiki: getActiveParam(SearchParams.wiki),
  })

  const clearAssetListData = () => {
    assets.value.splice(0)
    resultCount.value = 0
  }

  const sendSearchMessage = () => {
    const { sortOption } = useSorting(route, searchMode.value)
    clearAssetsOnNewEntries.value = true
    webSocket.send(JSON.stringify({ type: 'search', search: getSearchOptions(sortOption) }))
  }

  const loadAssets = async () => {
    const { startProgress } = useProgress()
    startProgress()

    loadingAssets.value = true
    clearAssetListData()

    if (webSocket.readyState !== 1) {
      webSocket.onopen = () => sendSearchMessage()
      return
    }

    sendSearchMessage()
  }

  const page = computed(() => (route.query.page ? Number(route.query.page) : 1))

  const pageCount = computed(() => {
    const pageSize = Number(getActiveParam(SearchParams.limit))
    return Math.ceil(resultCount.value / pageSize)
  })

  return {
    clearAssetListData,
    loadAssets,
    assets,
    loadingAssets,
    page,
    pageCount,
    resultCount,
    searchMode,
  }
}
