import Axios from 'axios'

import { BASE_URL } from '@/constants/appConstants'

const getAxios = (token, refreshToken) => {
  const axios = Axios.create()
  axios.defaults.headers.common = { Authorization: token }
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status !== 401) return
      if (!refreshToken) return
      await refreshToken(true)
      return Promise.reject(error)
    },
  )
  return axios
}

class BackendApi {
  static create() {
    return new BackendApi()
  }

  async getChainData() {
    const { data } = await Axios.get(`${BASE_URL}/api/info/info`)
    return data
  }

  async getTraits() {
    const response = await Axios.get(`${BASE_URL}/api/traits`)
    return response.data
  }

  async getTraitsRelations() {
    const response = await Axios.get(`${BASE_URL}/api/traits/relations`)
    return response.data
  }

  async getAsset(hex) {
    const {
      data: { asset },
    } = await Axios.get(`${BASE_URL}/api/asset/hex/${hex}`)
    return asset
  }

  async findAssetByName(name) {
    const {
      data: { asset },
    } = await Axios.post(`${BASE_URL}/api/asset/find`, { name })
    return asset
  }

  async findNameValuesByHex(hex) {
    const {
      data: { nameValues },
    } = await Axios.get(`${BASE_URL}/api/asset/hex/${hex}/name_values`)
    return nameValues
  }

  async getMessageToSign(address) {
    const { data } = await Axios.get(`${BASE_URL}/api/web3/${address}/nonce`)
    return data
  }

  async signMessage(address, message) {
    const { data } = await Axios.post(`${BASE_URL}/api/web3/${address}/signature`, { signature: message })
    return data
  }

  async renewToken(token) {
    const axios = getAxios(token)
    await axios.post(`${BASE_URL}/api/web3/renew-token`, {})
  }

  async updateDomains(token, domains, refreshToken) {
    const axios = getAxios(token, refreshToken)
    await axios.post(`${BASE_URL}/api/admins/domains`, { domains })
  }

  async updateDomainsTraits(token, domains, refreshToken) {
    const axios = getAxios(token, refreshToken)
    await axios.post(`${BASE_URL}/api/admins/domains-traits`, { domains })
  }

  async updateTraits(token, traits, refreshToken) {
    const axios = getAxios(token, refreshToken)
    await axios.put(`${BASE_URL}/api/admins/traits`, { traits })
  }

  async deleteTraits(token, traitsSlugs, refreshToken) {
    const axios = getAxios(token, refreshToken)
    await axios.post(`${BASE_URL}/api/admins/delete-traits`, { traitsSlugs })
  }

  async updateRelations(token, relations, refreshToken) {
    const axios = getAxios(token, refreshToken)
    await axios.put(`${BASE_URL}/api/admins/traits-relations`, { relations })
  }

  async deleteRelations(token, relations, refreshToken) {
    const axios = getAxios(token, refreshToken)
    await axios.post(`${BASE_URL}/api/admins/delete-traits-relations`, { relations })
  }

  async updateAsset(token, formData, refreshToken) {
    const axios = getAxios(token, refreshToken)
    await axios.post(`${BASE_URL}/api/admins/update-asset`, formData)
  }

  async updateAssetFeaturedImage(token, nameHex, featuredImageName, refreshToken) {
    const axios = getAxios(token, refreshToken)
    await axios.post(`${BASE_URL}/api/admins/update-asset-featured-image`, { nameHex, featuredImageName })
  }

  async setAssetMainMetadataDate(token, assetId, mainMetadataDate, refreshToken) {
    const axios = getAxios(token, refreshToken)
    await axios.post(`${BASE_URL}/api/admins/set-asset-main-metadata-date`, { assetId, mainMetadataDate })
  }

  async getUserMessages(token, options, refreshToken) {
    const axios = getAxios(token, refreshToken)
    return axios.post(`${BASE_URL}/api/user-messages/find`, options)
  }

  async postUserMessage(payload) {
    return Axios.post(`${BASE_URL}/api/user-message`, payload)
  }

  async closeUserMessage(token, id, refreshToken) {
    const axios = getAxios(token, refreshToken)
    return axios.post(`${BASE_URL}/api/user-messages/close/${id}`)
  }

  async updateTraitFeatured(token, traitSlug, featured, refreshToken) {
    const axios = getAxios(token, refreshToken)
    return axios.put(`${BASE_URL}/api/trait/featured/${traitSlug}`, { featured })
  }

  async updateTraitDescription(token, traitSlug, description, refreshToken) {
    const axios = getAxios(token, refreshToken)
    return axios.put(`${BASE_URL}/api/trait/description/${traitSlug}`, { description })
  }

  async updateTraitIsMainTrait(token, traitSlug, isMainTrait, refreshToken) {
    const axios = getAxios(token, refreshToken)
    return axios.put(`${BASE_URL}/api/trait/is-main-trait/${traitSlug}`, { isMainTrait })
  }

  async updateTraitIndex(token, traitSlug, index, refreshToken) {
    const axios = getAxios(token, refreshToken)
    return axios.put(`${BASE_URL}/api/trait/index/${traitSlug}`, { index })
  }

  async updateTraitSortCategory(token, traitSlug, sortCategory, refreshToken) {
    const axios = getAxios(token, refreshToken)
    return axios.put(`${BASE_URL}/api/trait/sort-category/${traitSlug}`, { sortCategory })
  }

  async updateTraitSortOption(token, traitSlug, sortValue, sortDirection, refreshToken) {
    const axios = getAxios(token, refreshToken)
    return axios.put(`${BASE_URL}/api/trait/sort-option/${traitSlug}`, { sortValue, sortDirection })
  }

  async updateTraitRelationIndex(token, parentSlug, childSlug, index, refreshToken) {
    const axios = getAxios(token, refreshToken)
    return axios.put(`${BASE_URL}/api/relation/index/parent/${parentSlug}/child/${childSlug}`, { index })
  }

  async updateTrait(token, originalSlug, newSlug, newName, refreshToken) {
    const axios = getAxios(token, refreshToken)
    return axios.put(`${BASE_URL}/api/trait/trait/${originalSlug}`, { slug: newSlug, name: newName })
  }

  async deleteTraitRelation(token, parentSlug, childSlug, refreshToken) {
    const axios = getAxios(token, refreshToken)
    return axios.delete(`${BASE_URL}/api/relation/parent/${parentSlug}/child/${childSlug}`)
  }

  async createTraitRelation(token, parentSlug, childSlug, refreshToken) {
    const axios = getAxios(token, refreshToken)
    return axios.post(`${BASE_URL}/api/relation`, { parentSlug, childSlug })
  }

  async createTrait(token, name, slug, refreshToken) {
    const axios = getAxios(token, refreshToken)
    return axios.post(`${BASE_URL}/api/trait`, { name, slug })
  }
}

export default BackendApi
