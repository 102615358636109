import { createWebHistory, createRouter } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../components/AppHome.vue'),
  },
  {
    path: '/hex/:hex',
    name: 'Asset Detail By Hex',
    component: () => import('../components/AssetDetail.vue'),
  },
  {
    path: '/name/:name(.*)',
    name: 'Asset Detail By Name',
    component: () => import('../components/AssetDetail.vue'),
  },
  {
    path: '/admin',
    name: 'Admin Panel',
    component: () => import('../components/AppAdmin.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
