import router from './router'

import SearchModes from '@/enums/SearchModes.js'

export const SearchParams = {
  date_end: 'date_end',
  date_start: 'date_start',
  dir: 'dir',
  expired: 'expired',
  limit: 'limit',
  page: 'page',
  search_mode: 'search_mode',
  search: 'search',
  sort_by: 'sort_by',
  trait: 'trait',
  traits: 'traits',
  wiki: 'wiki',
  age: 'age',
}

export const defaultParams = {
  [SearchParams.dir]: 'asc',
  [SearchParams.limit]: 100,
  [SearchParams.page]: 1,
  [SearchParams.search_mode]: SearchModes.name,
  [SearchParams.sort_by]: 'creation',
  [SearchParams.trait]: 'all',
  [SearchParams.traits]: '',
  [SearchParams.wiki]: false,
}

export const updateSearchParams = async (params, options) => {
  const url = new URL(window.location.href)

  params.forEach(({ key, value }) => {
    if (value) {
      url.searchParams.set(key, value)
    } else {
      url.searchParams.delete(key)
    }
  })

  const path = options?.keepPathName ? url.pathname : '/'

  await router.push({
    path,
    query: {
      date_end: url.searchParams.get(SearchParams.date_end) || undefined,
      date_start: url.searchParams.get(SearchParams.date_start) || undefined,
      dir: url.searchParams.get(SearchParams.dir) || undefined,
      expired: url.searchParams.get(SearchParams.expired) || undefined,
      limit: url.searchParams.get(SearchParams.limit) || undefined,
      page: url.searchParams.get(SearchParams.page) || undefined,
      search: url.searchParams.get(SearchParams.search) || undefined,
      search_mode: url.searchParams.get(SearchParams.search_mode) || undefined,
      sort_by: url.searchParams.get(SearchParams.sort_by) || undefined,
      trait: url.searchParams.get(SearchParams.trait) || undefined,
      traits: url.searchParams.get(SearchParams.traits) || undefined,
      wiki: url.searchParams.get(SearchParams.wiki) || undefined,
    },
  })
}

export const getActiveParam = (paramName, options = {}) => {
  const url = new URL(window.location.href)
  const param = url.searchParams.get(paramName)
  return param || options?.defaultOverride || defaultParams[paramName]
}
