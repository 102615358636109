<template>
  <div class="flex flex-row" id="app-header">
    <div class="flex items-center grow mx-5 p-3 md:p-5 content-center">
      <router-link to="/">
        <h1 class="relative -left-1 cursor-pointer">
          <img v-if="darkMode" src="/images/logo/nmcvision_night.png" class="w-36" />
          <img v-else src="/images/logo/nmcvision_day.png" class="w-36" />
        </h1>
      </router-link>
    </div>
    <div class="flex items-center flex-none mx-5 py-3 md:py-5">
      <div class="flex flex-row ml-5 cursor-pointer items-center">
        <InboxIcon class="mr-3 w-8 h-8 text-slate-600 dark:text-slate-100" @click="isUserMessageModalOpen = true" />
        <a href="https://twitter.com/nmc_vision" target="_blank">
          <TwitterIcon class="mr-2 w-6 h-6 text-slate-600 dark:text-slate-100" fill="currentColor" />
        </a>
        <svg
          v-if="darkMode"
          @click="onLightClicked"
          id="light"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="w-8 h-8 text-slate-600 dark:text-slate-100"
        >
          <path
            d="M12 2.25a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0V3a.75.75 0 01.75-.75zM7.5 12a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM18.894 6.166a.75.75 0 00-1.06-1.06l-1.591 1.59a.75.75 0 101.06 1.061l1.591-1.59zM21.75 12a.75.75 0 01-.75.75h-2.25a.75.75 0 010-1.5H21a.75.75 0 01.75.75zM17.834 18.894a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 10-1.061 1.06l1.59 1.591zM12 18a.75.75 0 01.75.75V21a.75.75 0 01-1.5 0v-2.25A.75.75 0 0112 18zM7.758 17.303a.75.75 0 00-1.061-1.06l-1.591 1.59a.75.75 0 001.06 1.061l1.591-1.59zM6 12a.75.75 0 01-.75.75H3a.75.75 0 010-1.5h2.25A.75.75 0 016 12zM6.697 7.757a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 00-1.061 1.06l1.59 1.591z"
          />
        </svg>
        <svg
          v-else
          id="dark"
          @click="onDarkClicked"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="w-8 h-8 text-slate-600 dark:text-slate-100"
        >
          <path
            fill-rule="evenodd"
            d="M9.528 1.718a.75.75 0 01.162.819A8.97 8.97 0 009 6a9 9 0 009 9 8.97 8.97 0 003.463-.69.75.75 0 01.981.98 10.503 10.503 0 01-9.694 6.46c-5.799 0-10.5-4.701-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 01.818.162z"
            clip-rule="evenodd"
          />
        </svg>
        <router-link v-if="isOnline" to="/admin">
          <AdminIcon class="ml-2 w-6 h-6 text-slate-600 dark:text-slate-100" />
        </router-link>
      </div>
    </div>
  </div>
  <UserMessageModal v-if="isUserMessageModalOpen" @close="$router.go(0)" />
</template>

<script setup>
import { ref } from 'vue'

import AdminIcon from '@/components/AdminIcon.vue'
import InboxIcon from '@/components/InboxIcon.vue'
import TwitterIcon from '@/components/TwitterIcon.vue'
import useDarkMode from '@/composables/useDarkMode'
import UserMessageModal from '@/components/UserMessageModal.vue'
import useWeb3Modal from '@/composables/useWeb3Modal'

const { isOnline } = useWeb3Modal()
const { darkMode, onDarkClicked, onLightClicked } = useDarkMode()

const isUserMessageModalOpen = ref(false)
</script>
