/**
 * @param {string} dateString
 * @returns {string}
 */
export const getDateStringFromDate = (dateString) => {
  let date = new Date(dateString)
  let year = date.getFullYear()

  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

/**
 * @param {string} dateString
 * @returns {string}
 */
export const formatDate = (dateString) => {
  const date = new Date(dateString)
  // return date/month/hear time in 24h format
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const hours = date.getHours()
  const minutes = date.getMinutes()

  const paddedDay = String(day).padStart(2, '0')
  const paddedMonth = String(month).padStart(2, '0')
  const paddedHours = String(hours).padStart(2, '0')
  const paddedMinutes = String(minutes).padStart(2, '0')

  return `${year}/${paddedMonth}/${paddedDay} ${paddedHours}:${paddedMinutes}`
}
