import { ref } from 'vue'

export const progress = ref(0)
const progressInterval = ref(null)

export default function useProgress() {
  const startProgress = () => {
    stopProgress()
    progressInterval.value = setInterval(() => {
      let newValue = Math.round(progress.value + 100 / 200)
      if (newValue >= 100) newValue = 99
      progress.value = newValue
    }, 100)
  }

  const stopProgress = () => {
    progress.value = 0
    if (progressInterval.value) clearInterval(progressInterval.value)
  }

  return {
    progress,
    startProgress,
    stopProgress,
  }
}
