import { computed, ref } from 'vue'
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/vue'
import { getAccount, watchAccount } from '@wagmi/core'
import { mainnet } from '@wagmi/core/chains'
import { useLocalStorage } from '@vueuse/core'

import BackendApi from '@/apis/BackendApi'

const chains = [mainnet]
const projectId = 'efeebd1ea0edc91e8a6c81fd1f198d0f'

const metadata = {
  name: 'NMC.Vision',
  description: 'NMC Vision admin access',
}

const wagmiConfig = defaultWagmiConfig({ metadata, projectId, chains })
const web3modal = createWeb3Modal({ defaultChain: mainnet, wagmiConfig, projectId, chains })

const web3ModalIsOpen = ref(false)
web3modal.subscribeState((newState) => (web3ModalIsOpen.value = newState.open))

const account = ref(getAccount())
const token = useLocalStorage('token', null)

const address = computed(() => account.value?.address || null)

const tinyAddress = computed(() => {
  if (!address.value) return null
  return `${address.value.slice(0, 6)}...${address.value.slice(-4)}`
})

const refreshToken = async (force) => {
  try {
    const backendApi = BackendApi.create()
    if (!account.value) return
    if (token.value && !force) return
    const { message } = await backendApi.getMessageToSign(address.value)
    const signature = await window.ethereum.request({
      method: 'personal_sign',
      params: [message, address.value],
    })
    const signMessageResponse = await backendApi.signMessage(address.value, signature)
    token.value = signMessageResponse.token
  } catch (error) {
    console.error(error)
  }
}

watchAccount(
  async (acc) => {
    account.value = acc
    if (!account.value?.address) {
      token.value = ''
      return
    }
    await refreshToken()
  },
  { immediate: false },
)

export default function useWeb3Modal() {
  const onConnect = async () => {
    try {
      await web3modal.open()
    } catch (error) {
      console.error(error)
    }
  }

  return {
    account,
    token,
    isOnline: computed(() => Boolean(address.value) && Boolean(token.value)),
    onConnect,
    refreshToken,
    tinyAddress,
    web3ModalIsOpen,
  }
}
