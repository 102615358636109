import useApp from '@/composables/useApp'

export const disableScroll = () => {
  const { state } = useApp()
  state.disableScroll.value = true
}

export const enableScroll = () => {
  const { state } = useApp()
  state.disableScroll.value = false
}
