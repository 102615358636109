export const BASE_URL = 'https://api.nmc.vision'
// export const BASE_URL = 'http://172.22.99.15:5555'
export const WEBSOCKET_URL = 'wss://ws.nmc.vision'
// export const WEBSOCKET_URL = 'ws://172.22.99.15:5888'

const ImmutableTraitsEnum = {
  assorted: 'assorted', //
  'assorted-10k-club': 'assorted-10k-club',
  'assorted-999-club': 'assorted-999-club',
  'assorted-99-club': 'assorted-99-club',
  'assorted-one-digit-club': 'assorted-one-digit-club',
  'assorted-one-letter-club': 'assorted-one-letter-club',
  'assorted-two-letters-club': 'assorted-two-letters-club',
  bit: 'bit',
  'bit-10k-club': 'bit-10k-club',
  'bit-999-club': 'bit-999-club',
  'bit-99-club': 'bit-99-club',
  'bit-one-digit-club': 'bit-one-digit-club',
  'bit-one-letter-club': 'bit-one-letter-club',
  'bit-two-letters-club': 'bit-two-letters-club',
  genesis: 'genesis',
  identities: 'identities',
  'identities-10k-club': 'identities-10k-club',
  'identities-999-club': 'identities-999-club',
  'identities-99-club': 'identities-99-club',
  'identities-one-digit-club': 'identities-one-digit-club',
  'identities-one-letter-club': 'identities-one-letter-club',
  'identities-two-letters-club': 'identities-two-letters-club',
  'one-name': 'one-name',
  punycodes: 'punycodes',
  unicode: 'unicode',
}

/**
 * These traits are treated a special way by the server.
 */
export const immutableTraits = Object.keys(ImmutableTraitsEnum)
