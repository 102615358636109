<template>
  <div
    class="flex flex-row shrink-0 flex-nowrap whitespace-nowrap mx-5 mb-5 p-3 md:p-5 bg-slate-200 dark:bg-slate-700 rounded-md shadow-md overflow-auto"
    style="overflow-x: auto"
  >
    <HorizontalMenuTrait
      v-for="trait in featuredTraits"
      :key="`horizontal-menu-trait-${trait.slug}`"
      :trait="trait"
      :activeTrait="activeTrait"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { SearchParams, getActiveParam } from '@/services/searchParams'
import HorizontalMenuTrait from '@/components/HorizontalMenuTrait.vue'
import useTraits from '@/composables/useTraits'

const { traits } = useTraits()

const activeTrait = getActiveParam(SearchParams.trait)
const featuredTraits = computed(() => traits.value.filter(({ slug, featured }) => slug === 'all' || featured))
</script>
