export const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text)
  } catch (error) {
    console.error(error)
  }
}

/**
 * @template T
 * @param {T[]} array
 * @returns {T[]}
 */
export const uniqueArray = (array) => [...new Set(array)]
