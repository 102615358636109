<template>
  <div class="relative">
    <div class="flex flex-row w-full lg:w-80">
      <div class="absolute inset-y-0 left-0 top-3 items-center pl-3 pointer-events-none">
        <MagnifierIcon />
      </div>
      <input
        type="search"
        id="search"
        v-model="search"
        :placeholder="searchPlaceholder"
        class="text-sm w-full lg:text-base bg-slate-700 dark:bg-slate-900 border border-slate-600 text-slate-100 rounded-t-md focus:ring-blue-500 focus:border-blue-500 block p-2 pl-10 mr-2 md:mr-0 placeholder-slate-400 dark:placeholder-slate-500"
      />
      <div
        @click="toggleShowFilters"
        class="lg:hidden bg-slate-700 hover:bg-slate-800 dark:bg-slate-900 dark:hover:bg-slate-800 ml-1 p-2 text-slate-100 rounded-md shadow-md cursor-pointer border border-slate-600"
      >
        <FilterIcon />
      </div>
    </div>
    <div
      class="flex flex-row p-2 pl-5 whitespace-nowrap flex-wrap bg-slate-100 dark:bg-slate-800 rounded-md lg:rounded-none lg:rounded-b-md mt-3 lg:mt-0 shadow-md"
    >
      <div class="flex items-center m-1 whitespace-nowrap">
        <input
          type="radio"
          :checked="searchMode === SearchModes.name"
          class="w-4 h-4 text-blue-600 bg-slate-100 rounded border-slate-300 ring-0 focus:ring-offset-0 dark:bg-slate-700 dark:border-slate-600"
          style="box-shadow: none"
          @click="searchMode = SearchModes.name"
        />
        <label for="default-checkbox" class="ml-2 text-xs font-bold text-slate-900 dark:text-slate-300">Name</label>
      </div>
      <div class="flex items-center m-1 whitespace-nowrap" style="flex-grow: 1">
        <input
          type="radio"
          :checked="searchMode === SearchModes.nameValues"
          class="w-4 h-4 text-blue-600 bg-slate-100 rounded border-slate-300 ring-0 focus:ring-offset-0 dark:bg-slate-700 dark:border-slate-600"
          style="box-shadow: none"
          @click="searchMode = SearchModes.nameValues"
        />
        <label for="default-checkbox" class="ml-2 text-xs font-bold text-slate-900 dark:text-slate-300">
          Data (Value)
        </label>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { watchDebounced } from '@vueuse/core'

import { getActiveParam, SearchParams, updateSearchParams } from '@/services/searchParams.js'
import { toggleShowFilters } from '@/composables/useShowFilters'
import SearchModes from '@/enums/SearchModes.js'
import useAssetList from '@/composables/useAssetList'

import FilterIcon from '@/components/FilterIcon.vue'
import MagnifierIcon from '@/components/MagnifierIcon.vue'

const route = useRoute()
const { clearAssetListData, loadAssets, searchMode } = useAssetList(route)

const search = ref(getActiveParam(SearchParams.search))
const searchPlaceholder = computed(() =>
  searchMode.value === SearchModes.name ? 'Search by name or hex' : 'Search by data in names values',
)

watch(
  () => searchMode.value,
  async (value) => {
    clearAssetListData()
    await updateSearchParams([
      { key: SearchParams.search_mode, value },
      { key: SearchParams.page, value: '' },
    ])
    loadAssets()
  },
  { immediate: false },
)

watchDebounced(
  () => search.value,
  async () => {
    await updateSearchParams([
      { key: SearchParams.search, value: search.value },
      { key: SearchParams.page, value: '' },
    ])
    loadAssets()
  },
  { debounce: 500, maxWait: 1000 },
)
</script>

<style>
/* Fix for IOS */
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: 14px;
}
</style>
