export const sortTraits = (traits) => {
  return traits.sort((a, b) => {
    if (a.featured && !b.featured) return -1
    if (!a.featured && b.featured) return 1
    if (a.index < b.index) return -1
    if (a.index > b.index) return 1
    return a.slug.localeCompare(b.slug)
  })
}

export const sortRelations = (relations) => {
  return relations.sort((a, b) => {
    if (a.parent.featured && !b.parent.featured) return -1
    if (!a.parent.featured && b.parent.featured) return 1
    if (a.parent.index < b.parent.index) return -1
    if (a.parent.index > b.parent.index) return 1
    return a.parent.slug.localeCompare(b.parent.slug)
  })
}

export const sortAssetTraits = (traits) => {
  const chronological = traits.find(({ slug }) => slug === 'chronological')
  const otherTraits = traits.filter(({ slug }) => slug !== 'chronological')
  return [...otherTraits, chronological].filter(Boolean)
}
