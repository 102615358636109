import { defaultParams, SearchParams } from '@/services/searchParams'

export const defaultTraitSlug = defaultParams[SearchParams.trait]

export const allTrait = {
  name: 'All',
  slug: defaultTraitSlug,
  default_sort_category: 'name',
  default_sort_value: 'creation',
  default_sort_direction: 'asc',
}
