import { allTrait } from '@/constants/traitsConstants'
import SearchModes from '@/enums/SearchModes'

export const SortCategories = {
  name: 'Name',
  image: 'Image',
  event: 'Event',
}

export const SortFields = {
  name: 'name',
  value_creation: 'value_creation',
  creation: 'creation',
  expiration: 'expiration',
  main_data: 'main_data',
  age: 'age',
}

export const sortOptionsExcludedInNameValueMode = [SortFields.expiration, SortFields.main_data]
export const sortOptionsExcludedInNameMode = [SortFields.value_creation]

export const SortDirections = {
  asc: 'asc',
  desc: 'desc',
}

const sortingOptionMap = {
  imageCreationOldest: {
    field: SortFields.main_data,
    direction: SortDirections.asc,
    label: 'Image/Event (Oldest)',
    value: 'main_data',
  },
  imageCreationNewest: {
    field: SortFields.main_data,
    direction: SortDirections.desc,
    label: 'Image/Event (Newest)',
    value: 'main_data',
  },
  oldestValue: {
    field: SortFields.value_creation,
    direction: SortDirections.asc,
    label: 'Name Data (Value) Date (Oldest)',
    value: 'value_creation',
  },
  newestValue: {
    field: SortFields.value_creation,
    direction: SortDirections.desc,
    label: 'Name Data (Value) Date  (Newest)',
    value: 'value_creation',
  },
  oldest: {
    field: SortFields.creation,
    direction: SortDirections.asc,
    label: 'Name Creation (Oldest)',
    value: 'creation',
  },
  newest: {
    field: SortFields.creation,
    direction: SortDirections.desc,
    label: 'Name Creation (Newest)',
    value: 'creation',
  },
  oldestCreation: {
    field: SortFields.age,
    direction: SortDirections.asc,
    label: 'Activity (Oldest)',
    value: 'age',
  },
  newestCreation: {
    field: SortFields.age,
    direction: SortDirections.desc,
    label: 'Activity (Newest)',
    value: 'age',
  },
  nameUp: {
    field: SortFields.name,
    direction: SortDirections.asc,
    label: 'Name (A-Z)',
    value: 'name',
  },
  nameDown: {
    field: SortFields.name,
    direction: SortDirections.desc,
    label: 'Name (Z-A)',
    value: 'name',
  },
  expiredUp: {
    field: SortFields.expiration,
    direction: SortDirections.asc,
    label: 'Expiration (Oldest)',
    value: 'expiration',
  },
  expiredDown: {
    field: SortFields.expiration,
    direction: SortDirections.desc,
    label: 'Expiration (Newest)',
    value: 'expiration',
  },
}

export const sortingOptions = Object.values(sortingOptionMap)

export const findSortingOption = (sortValue, sortDirection) => {
  return sortingOptions.find(({ field, direction }) => field === sortValue && direction === sortDirection)
}

const defaultSortDirection = 'asc'

export const getSortingOption = (sortValue, sortDirection, currentTrait, searchMode = null) => {
  const getDefaultSortValue = () => {
    if (!searchMode) return 'creation'
    return searchMode === SearchModes.name ? SortFields.creation : SortFields.value_creation
  }

  const defaultSortValue = getDefaultSortValue()

  if (sortValue) {
    const foundOption = findSortingOption(sortValue, sortDirection || defaultSortDirection)

    if (searchMode) {
      if (searchMode === SearchModes.name && sortOptionsExcludedInNameMode.includes(sortValue)) {
        const sortingOption = findSortingOption(defaultSortValue, sortDirection || defaultSortDirection)
        const url = new URL(window.location.href)
        const searchParams = new URLSearchParams(url.search)
        searchParams.set('sort_by', sortingOption.field)
        url.search = searchParams.toString()
        window.history.replaceState({}, '', url)

        return sortingOption
      }

      if (searchMode === SearchModes.nameValues && sortOptionsExcludedInNameValueMode.includes(sortValue)) {
        const sortingOption = findSortingOption(defaultSortValue, sortDirection || defaultSortDirection)
        const url = new URL(window.location.href)
        const searchParams = new URLSearchParams(url.search)
        searchParams.set('sort_by', sortingOption.field)
        url.search = searchParams.toString()
        window.history.replaceState({}, '', url)
        return sortingOption
      }
    }

    if (foundOption) return foundOption
  }

  if (currentTrait.slug === allTrait.slug) {
    return findSortingOption(defaultSortValue, sortDirection || defaultSortDirection)
  }

  const foundOption = findSortingOption(
    currentTrait.default_sort_value,
    sortDirection || currentTrait.default_sort_direction,
  )

  return foundOption || findSortingOption(defaultSortValue, sortDirection || defaultSortDirection)
}
