import { createApp } from 'vue'
import { createVuetify } from 'vuetify'
import { VMenu, VTooltip } from 'vuetify/components'
import VueDatePicker from '@vuepic/vue-datepicker'
import VueExcelEditor from 'vue3-excel-editor'
import VueLazyLoad from 'vue3-lazyload'

import App from './App.vue'
import router from './services/router'

const vuetify = createVuetify({
  components: { VMenu, VTooltip },
})

createApp(App) //
  .use(router)
  .use(VueExcelEditor)
  .use(VueLazyLoad)
  .use(vuetify)
  .component('VueDatePicker', VueDatePicker)
  .mount('#app')
