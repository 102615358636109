import { computed, ref } from 'vue'

import { allTrait } from '@/constants/traitsConstants'
import { sortRelations } from '@/services/traitsService'
import { uniqueArray } from '@/services/helpers'
import BackendApi from '@/apis/BackendApi'
import useTraits from '@/composables/useTraits'

const backendApi = BackendApi.create()

const relations = ref([])
const loadingRelations = ref(false)

export default function useRelations() {
  const { traits } = useTraits()

  const hydrateRelations = async (force = false) => {
    try {
      if (force) relations.value.splice(0)
      if (!force && (loadingRelations.value || relations.value.length)) return
      loadingRelations.value = true
      relations.value.splice(0)
      relations.value.push(...(await backendApi.getTraitsRelations()))
    } catch (error) {
      console.error(error)
    } finally {
      loadingRelations.value = false
    }
  }

  hydrateRelations()

  const traitsRelations = computed(() => {
    return sortRelations(
      relations.value.map(({ parent_trait, child_trait, index, supply }) => ({
        parent: traits.value.find(({ slug }) => slug === parent_trait),
        child: { ...traits.value.find(({ slug }) => slug === child_trait), supply },
        index,
      })),
    )
  })

  const relationsMap = computed(() => {
    const map = traitsRelations.value.reduce((acc, relation) => {
      if (!acc[relation.parent.slug]) acc[relation.parent.slug] = { parent: relation.parent, children: [] }
      if (relation.child) acc[relation.parent.slug].children.push({ ...relation.child, index: relation.index })
      return acc
    }, {})

    for (const key in map) {
      map[key].children = map[key].children.sort((a, b) => {
        const indexA = a.index || Infinity
        const indexB = b.index || Infinity
        return indexA - indexB || a.name.localeCompare(b.name)
      })
    }

    const allParentTraitsSlugsInRelations = uniqueArray(relations.value.map(({ parent_trait }) => parent_trait))

    const parentTraitsNotInRelations = traits.value.filter(
      ({ slug }) => !allParentTraitsSlugsInRelations.includes(slug) && slug !== allTrait.slug,
    )

    for (const trait of parentTraitsNotInRelations)
      if (!map[trait.slug]) map[trait.slug] = { parent: trait, children: [] }

    const orderedMap = Object.keys(map)
      .sort((a, b) => {
        const indexA = map[a].parent.index || Infinity
        const indexB = map[b].parent.index || Infinity
        return indexA - indexB || map[a].parent.name.localeCompare(map[b].parent.name)
      })
      .reduce((acc, key) => {
        acc[key] = map[key]
        return acc
      }, {})

    return orderedMap
  })

  return {
    hydrateRelations, //
    loadingRelations,
    relations,
    relationsMap,
    traitsRelations,
  }
}
