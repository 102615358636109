import { ref } from 'vue'

import { allTrait } from '@/constants/traitsConstants'
import { getSortingOption } from '@/services/sorting'
import { SearchParams } from '@/services/searchParams'
import useTraits from '@/composables/useTraits'

const sortOption = ref(null)

export default function useSorting(route, searchMode = null) {
  const { traits } = useTraits()

  const currentTrait = traits.value.find((trait) => trait.slug === route.query.trait) || allTrait

  sortOption.value = getSortingOption(
    route.query[SearchParams.sort_by],
    route.query[SearchParams.dir],
    currentTrait,
    searchMode,
  )

  return {
    sortOption, //
  }
}
